<template>
  <div>
    <template v-if="debugMode">
      {{ readyToRecord }}
      <v-btn @click="startCapture">
        start
      </v-btn>

      <v-btn @click="takePicture">
        take image
      </v-btn>

      <v-btn @click="s3upload">
        upload
      </v-btn>
    </template>

    <canvas
      id="takePhotoCanvas"
      width="1000px"
      height="500px"
      ref="takePhotoCanvas"
    />

    <video
      id="video"
      autoplay
      ref="video"
      style="display: none;"
    />
  </div>
</template>

<script>
import proctoringUploader from "../../../helpers/proctoring_uploader"

const displayMediaOptions = {
  video: {
    cursor: "always",
  },
  audio: false,
}

export default {
  name: "QuizScreenRecord",
  props: {
    openStream: {
      type: Boolean,
      default: false,
    },
    isProctoringActive: {
      type: Boolean,
      default: false,
    },
    quiz: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    debugMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    captureStream: null,
    imageCapture: null,
    imageBitmap: null, // to delete
    timer: null,
  }),
  computed: {
    folderName() {
      return proctoringUploader.folderName({
        quizId: this.quiz?.id || 99999999,
        userId: this.user?.id || 99999999,
      }) + "/screen"
    },
    readyToRecord() {
      return this.captureStream !== null
    },
    screenshotFrameInterval() {
      return parseInt(this.quiz?.proctoring_frame_interval) || 1000
    },
  },
  methods: {
    startCapture() {
      try {
        navigator.mediaDevices.getDisplayMedia(displayMediaOptions).then(mediaStream => {
          this.captureStream = mediaStream
          this.$refs.video.srcObject = mediaStream

          const track = mediaStream.getVideoTracks()[0]

          this.imageCapture = new ImageCapture(track)
          this.startProctoringLoop()
        })
      } catch(err) {
        console.error("Error: " + err)
      }
    },
    takePicture() {

      this.imageCapture.grabFrame()
        .then(imageBitmap => {
          const canvas = document.querySelector("#takePhotoCanvas")
          this.drawCanvas(canvas, imageBitmap)
          this.imageBitmap = imageBitmap
          this.s3upload()
        })
        .catch(error => console.log(error))
    },

    drawCanvas(canvas, img) {
      canvas.width = getComputedStyle(canvas).width.split("px")[0]
      canvas.height = getComputedStyle(canvas).height.split("px")[0]
      let ratio  = Math.min(canvas.width / img.width, canvas.height / img.height)
      let x = (canvas.width - img.width * ratio) / 2
      let y = (canvas.height - img.height * ratio) / 2
      canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height)
      canvas.getContext("2d").drawImage(img, 0, 0, img.width, img.height, x, y, img.width * ratio, img.height * ratio)
    },

    s3upload() {
      const uploader = proctoringUploader.setup()
      proctoringUploader.upload({
        uploader,
        imgBase64: this.base64Image(),
        path: this.folderName,
      })
    },

    base64Image() {
      return this.$refs.takePhotoCanvas.toDataURL()
    },

    startProctoringLoop() {
      this.timer = setInterval(() => {
        if(this.isProctoringActive) this.takePicture()
      },this.screenshotFrameInterval)
    },
    stopProctoringLoop() {
      clearInterval(this.timer)
    },
    beforeDestroy() {
      // this.stop()
      this.stopProctoringLoop()
    },
  },
  watch: {
    openStream: {
      handler(val) {
        if(val) { this.startCapture() }
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
  #takePhotoCanvas{
    position: fixed;
    top: -1000px;
    left: -1000px;
  }
</style>

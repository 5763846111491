import { S3Client } from "@aws-sdk/client-s3"
import { XhrHttpHandler } from "@aws-sdk/xhr-http-handler"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"
import { Upload } from "@aws-sdk/lib-storage"

import { Buffer } from "buffer"

const proctoringUploader = {

  setup() {
    const bucketName = window.PROCTORING_SOURCE_BUCKET
    const CognitoRegion = window.PROCTORING_SOURCE_BUCKET_REGION
    const IdentityPoolId = window.PROCTORING_COGNITO_IDENTITY_POOL_ID

    // Rewrite in v3
    const credentials = fromCognitoIdentityPool({
      clientConfig:   {
        region: CognitoRegion,
      },
      identityPoolId: IdentityPoolId,

    })

    let s3Client = new S3Client({
      region: CognitoRegion,
      requestHandler: new XhrHttpHandler({}),
      credentials,
    })

    return {
      s3Client,
      bucketName,
    }
  },

  /**
   * @params {Function} callback - callback progress
   */
  async upload({ uploader: { s3Client, bucketName }, imgBase64, path, metadata, tags, callback } = {
    path:     null,
    metadata: {},
    tags:     [],
    callback: () => {},
  }) {
    let file = Buffer.from(imgBase64.replace(/^data:image\/\w+;base64,/, ""), "base64")
    let fileName = `${path.replace(/\//gi, "_")}_img_${Date.now()}.jpg`
    let filePath = path + "/" + fileName

    try {
      const uploadOperation = new Upload({
        client: s3Client,
        params: {
          Bucket:      bucketName,
          Key:         filePath,
          Body:        file,
          Metadata:    metadata,
        },
        tags,
      })

      await uploadOperation.done()
      if (typeof callback === "function") {
        callback()
      }

    } catch (e) {
      console.error("Upload error", e)
    }
  },

  async uploadAudioFile(
    { uploader: { s3Client, bucketName }, file, path, metadata, tags, timeStamp,progressCallback } =
    { path:null, metadata: {}, tags: [], progressCallback: () => {}, timeStamp: "" }
  ) {
    let fileName = `${path.replace(/\//gi, "_")}_audio_${timeStamp}.webm`
    let filePath = path + "/" + fileName

    try {
      const uploadOperation = new Upload({
        client: s3Client,
        params: {
          Bucket:      bucketName,
          Key:         filePath,
          Body:        file,
          Metadata:    metadata,
          ContentType: "audio/webm",
        },
        tags,
      })

      uploadOperation.on("httpUploadProgress", (progress) => {
        const uploaded = parseInt((progress.loaded * 100) / progress.total)
        progressCallback(uploaded)
      })

      return uploadOperation.done()
    } catch (e) {
      console.error("Upload error", e)
    }
  },

  folderName({ quizId, userId }) {
    return `${quizId}/${userId}`
  },

}

export default proctoringUploader

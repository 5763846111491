<template>
  <div />
</template>

<script>

import { mapGetters } from "vuex"
import { AudioRecorder } from "../../helpers/audioRecorder"
import proctoringUploader from "../../helpers/proctoring_uploader"

export default {
  name: "AudioRecorder",
  props: {
    isProctoringActive: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    quiz: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    uploaded: false,
    timer: null,
  }),
  methods: {
    record() {
      this.audioRecorder.startMediaRecorder()
    },
    stop() {
      this.audioRecorder.stopMediaRecorder()
    },

    // upload() {
    //   this.stop()
    //   console.log("upload")
    //   this.audioRecorder.uploadTo(() => {
    //     console.log("uploaded")
    //     this.uploaded = true
    //   })
    // },

    // createTimer() {
    //   this.timer = setInterval(() => {
    //     this.uploadChunks()
    //   }, 3000)
    // },
    //
    // uploadChunks() {
    //   audioRecorder.uploadChunksTo(this.pathName)
    //   console.log("uploadedChunks")
    // },

  },
  computed: {
    ...mapGetters(["currentUser"]),
    pathName() {
      return proctoringUploader.folderName({
        quizId: this.quiz.id,
        userId: this.currentUser.id,
      }) + "/audio"
    },

    audioRecorder() {
      return new AudioRecorder(this.quiz?.id, this.currentUser?.id)
    },

  },
  watch: {
    isProctoringActive: {
      handler(newValue) {
        if (newValue) {
          this.record()
        } else {
          this.stop()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // this.$root.$on("upload-audio-proctoring", this.upload)
    // this.createTimer()
  },
  beforeDestroy() {
    this.stop()
    //clearInterval(this.timer)
  },
}

</script>

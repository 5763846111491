<template>
  <div>
    <v-btn @click="record">
      record
    </v-btn>
    <v-btn @click="stop">
      stop
    </v-btn>

    <v-btn @click="getFileFromIndexDB">
      dl
    </v-btn>
    <v-btn @click="upload">
      upload
    </v-btn>

    <v-btn @click="uploadChunks">
      upload chunks
    </v-btn>

    <v-btn @click="cleanIndexedDB">
      clear
    </v-btn>
    <br>
    Uploaded {{ uploaded }}
  </div>
</template>

<script>

import audioRecorder from "../../helpers/audioRecorder"

export default {
  name: "AudioRecorderPoc",
  data: () => ({
    uploaded: false,
    mediaRecorder: null,
  }),
  methods: {
    record() {
      console.log("record")
      audioRecorder.startMediaRecorder()
    },
    stop() {
      audioRecorder.stopMediaRecorder()
    },

    upload() {
      console.log("upload")
      audioRecorder.uploadTo("test_audio_2022/filename", () => this.uploaded = true)
    },

    uploadChunks() {
      audioRecorder.uploadChunksTo("test_audio_2022/filename")
    },

    ...audioRecorder,
  },

}
</script>

import { openDB } from "idb"
import proctoringUploader from "./proctoring_uploader"

const dbName = "evalmee"
const objectStoreName = "running-shark"
const recorderTimeSlice = 3000
let lastTimeStamp = null
let mediaRecorder = null


export class AudioRecorder {

  /**
   * @param quizId
   * @param userId
   */
  constructor(quizId, userId) {
    this.quizId = quizId
    this.userId = userId
  }

  get sessionId() {
    return `a-${this.quizId}-${this.userId}`
  }

  get pathName() {
    return proctoringUploader.folderName({
      quizId: this.quizId,
      userId: this.userId,
    }) + "/audio"
  }

  /**
   * @param {Blob} blobData
   * @return {nul}
   */
  download(blobData) {
    const url = window.URL.createObjectURL(blobData)
    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    a.download = "test.webm"
    document.body.appendChild(a)
    a.click()
    setTimeout(() => {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 100)
  }

  async getFileFromIndexDB()  {
    this.download(await this.fullBlob())
  }

  /**
   * @return {Promise<Blob>}
   */
  async fullBlob(){
    const data = await this
      .indexedDb()
      .then(x => x.getAll( objectStoreName))

    const chunks = data.filter(x => x.sessionId === this.sessionId).map(x => x.chunk)

    // console.log(chunks)

    return new Blob(chunks, { type: "audio/webm;codecs=opus" })
  }

  async firstTimeStamp() {
    const data = await this
      .indexedDb()
      .then(x => x.getAll( objectStoreName))

    return data[0].timestamp
  }

  async hasDataToUpload() {
    const data = await this
      .indexedDb()
      .then(x => x.getAll( objectStoreName))

    return data.length > 0
  }

  /**
   * Upload chunks by chunks
   * @return {Promise<IDBDatabase>}
   */
  async uploadChunksTo(path){
    const data = await this
      .indexedDb()
      .then(x => x.getAll( objectStoreName))

    const chunksToUpload = data.filter(x => x.sync === false)


    for (const x of chunksToUpload) {
      console.log("uploading chunk", x)

      const callback = () => {
        this.indexedDb().then(db => {
          db.delete(objectStoreName, x.id)
          console.log("deleted chunk", x.id)
        })
      }

      const blob = new Blob([x.chunk], { type: "audio/webm;codecs=opus" })
      await this.s3ChunkUpload(blob, path, callback, x.timestamp )
    }

    // chunksToUpload.forEach(x => {
    //   console.log("uploading chunk", x)
    //
    //   const callback = () => {
    //     this.db1().then(db => {
    //       db.delete(objectStoreName, x.id)
    //       console.log("deleted chunk", x.id)
    //     })
    //   }
    //
    //   const blob = new Blob([x.chunk], { type: "audio/webm;codecs=opus" })
    //   await this.s3ChunkUpload(blob, path, callback, x.timestamp )
    // })
  }

  /**
   * Upload full audio file from all blobs merged into one
   * @return {Promise<IDBDatabase>}
   */
  async uploadTo(callback, progressCallback) {
    const fullCallback = () => {
      this.cleanIndexedDB()
      callback()
    }

    this.s3Upload(await this.fullBlob(), this.pathName, await this.firstTimeStamp(), fullCallback, progressCallback)
  }

  s3Upload(blob, path, timeStamp, callback, progressCallback) {
    const uploader = proctoringUploader.setup()

    proctoringUploader.uploadAudioFile({
      uploader,
      file: blob,
      path: path,
      timeStamp,
      progressCallback,
    }).then(callback)
  }

  async s3ChunkUpload(blob, path, callback, timeStamp) {
    const uploader = proctoringUploader.setup()

    await proctoringUploader.uploadAudioFile({
      uploader,
      file: blob,
      path,
      timeStamp,
      callback: callback,
    })
  }

  async storeChunkToIndexedDB(chunk) {
    const db1 = await this.indexedDb()

    await db1.add(
      objectStoreName,
      {
        chunk: chunk.blob,
        sync: false ,
        timestamp: chunk.timestamp,
        sessionId: this.sessionId,
      },
    )
    db1.close()
  }

  /**
   * @return {Promise<IDBDatabase>}
   */
  async indexedDb() {
    return await openDB(dbName, 1, {
      upgrade(db) {
        db.createObjectStore(objectStoreName, { autoIncrement: true, keyPath: "id" })
      },
    })
  }

  async cleanIndexedDB() {
    await this.indexedDb()
      .then(x => x.clear(objectStoreName))
    //TODO: close the database
  }

  startMediaRecorder() {
    this.updateTimeStamp()

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      return console.log("getUserMedia not supported on your browser!")
    }

    console.log("getUserMedia supported.")
    navigator.mediaDevices
      .getUserMedia ({ audio: true })
      .then((stream) => {
        // console.log("getUserMedia stream:", stream)
        mediaRecorder = new MediaRecorder(stream)
        // console.log("MediaRecorder started.")
        mediaRecorder.start(recorderTimeSlice)
        mediaRecorder.ondataavailable = (e) =>{
          this.storeChunkToIndexedDB({ blob: e.data, timestamp: lastTimeStamp })
          this.updateTimeStamp()
        }
      })
      .catch((err) => {
        console.log("The following getUserMedia error occurred: " + err)
      })
  }

  stopMediaRecorder() {
    if(mediaRecorder?.state !== "recording")  return
    mediaRecorder.stop()
  }

  updateTimeStamp() {
    lastTimeStamp = Math.floor(Date.now() / 1000)
  }

}

export default AudioRecorder

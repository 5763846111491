<template>
  <div>
    <div class="container">
      <v-row>
        <v-col cols="6">
          <div>
            <!--        <code v-if="device">{{ device.label }}</code>-->
            <div class="border">
              <vue-web-cam
                ref="webcam"
                :device-id="deviceId"
                width="100%"
                height="300"
                @started="onStarted"
                @stopped="onStopped"
                @error="onError"
                @cameras="onCameras"
                @camera-change="onCameraChange"
              />
            </div>

            <div class="row">
              <div class="col-md-12">
                <v-select
                  v-model="camera"
                  :items="devices"
                  item-text="label"
                  item-value="deviceId"
                  solo
                  dense
                  hide-details
                />
              </div>
              <div class="col-md-12">
                <v-btn
                  small
                  color="primary"
                  @click="onCapture"
                >
                  Take 1 photo
                </v-btn>
                <v-card class="mt-2">
                  <v-card-text>
                    <v-switch
                      small
                      v-model="capture_loop"
                      :label="`Continuous capture at ${framerate}img/s`"
                      hide-details
                    />
                    <v-text-field
                      v-model="interval"
                      label="Interval in ms for continuous capture ⬆️"
                      persistent-hint
                      hint="Restart capture to ack change"
                    />

                    Images sent: {{ imagesSent }}
                  </v-card-text>
                </v-card>

                <v-card class="mt-2">
                  <v-card-text>
                    <v-text-field
                      v-model="customFolderName"
                      label="Folder name in bucket"
                    />
                    <v-text-field
                      v-model="metadata"
                      label="Metadata"
                    />
                    Ex: {"answer": "42"}
                    <v-text-field
                      v-model="tags"
                      label="Tags"
                    />
                    Ex: key1=value1&key2=value2
                  </v-card-text>
                </v-card>
              <!--            <v-btn  @click="onStop">Stop Camera</v-btn>-->
                <!--            <v-btn @click="onStart">Start Camera</v-btn>-->
              </div>
            </div>
          </div>
          <div>
            <figure class="figure">
              <img
                :src="img"
                class="img-responsive"
              >
            </figure>
          </div>
        </v-col>
        <v-col cols="6">
          <quiz-screen-record
            debug-mode
            is-proctoring-active
          />
        </v-col>
        <v-col cols="6">
          <audio-recorder-poc />

          ---------------
          <v-switch v-model="recordAudio">
            Record Audio
          </v-switch>

          <audio-recorder
            :is-proctoring-active="recordAudio"
            :user="{id: 12}"
            :quiz="{id: 9999999}"
          />

          <mic-vu-meter class="vu_meter" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { WebCam } from "vue-web-cam"
import proctoringUploader from "../../helpers/proctoring_uploader"
import QuizScreenRecord from "../../components/quiz/evalmee/quiz_screen_record.vue"
import AudioRecorderPoc from "./audioRecorderPoc.vue"
import AudioRecorder from "./audioRecorder.vue"
import MicVuMeter from "../../components/quiz/evalmee/mic_vu_meter.vue"

export default {
  name: "CameraTest",
  props:{
    folderName: { type: String, default: "default" },
  },
  data() {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      capture_loop: false,
      customFolderName: "",
      metadata: "{}",
      tags: [],
      interval: 1000,
      intervalMethod: Function,
      imagesSent: 0,
      recordAudio: false,
    }
  },
  components: {
    MicVuMeter,
    AudioRecorderPoc,
    AudioRecorder,
    QuizScreenRecord,
    "vue-web-cam": WebCam,
  },
  computed: {
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId)
    },
    framerate() {
      return  Math.round(100000 / this.interval) / 100
    },
  },
  watch: {
    camera: function(id) {
      this.deviceId = id
    },
    devices: function() {
      // Once we have a list select the first one
      // eslint-disable-next-line no-unused-vars
      const [first, ...tail] = this.devices
      if (first) {
        this.camera = first.deviceId
        this.deviceId = first.deviceId
      }
    },
    capture_loop() {
      window.clearInterval(this.intervalMethod)
      this.intervalMethod = window.setInterval(() => {
        if(this.capture_loop) {
          this.onCapture()
          this.imagesSent += 1
        }
      }, this.interval)
    },
  },
  mounted() {
    this.customFolderName = this.folderName
  },
  methods: {
    onCapture() {
      this.img = this.$refs.webcam.capture()
      this.s3upload()
    },
    onStarted(stream) {
      console.log("On Started Event", stream)
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream)
    },
    onStop() {
      this.$refs.webcam.stop()
    },
    onStart() {
      this.$refs.webcam.start()
    },
    onError(error) {
      console.log("On Error Event", error)
    },
    onCameras(cameras) {
      this.devices = cameras
      console.log("On Cameras Event", cameras)
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId
      this.camera = deviceId
      console.log("On Camera Change Event", deviceId)
    },

    s3upload() {
      const uploader = proctoringUploader.setup()
      proctoringUploader.upload({
        uploader,
        imgBase64: this.img,
        path: this.customFolderName || this.folderName,
        metadata: JSON.parse(this.metadata),
        tags: this.tags,
      })
    },
  },
}
</script>

<style scoped>
  .img-responsive{
    width: 100%;
  }
</style>

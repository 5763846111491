<template>
  <div ref="micVuMeter" class="micVuMeter">
    <canvas
      id="canvas"
      :width="10"
      :height="height"
    />
  </div>
</template>

<script>
const width = 10
export default {
  name: "micVuMeter",
  computed: {
    height() {
      return this.$refs?.micVuMeter?.clientHeight || 100
    },
    width() {
      return width
    },
  },
  mounted() {

    const that = this
    navigator.getUserMedia = navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia
    if (navigator.getUserMedia) {
      navigator.getUserMedia({
        audio: true,
      },
      function(stream) {
        let audioContext = new AudioContext()
        let analyser = audioContext.createAnalyser()
        let microphone = audioContext.createMediaStreamSource(stream)
        let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1)

        analyser.smoothingTimeConstant = 0.8
        analyser.fftSize = 1024

        microphone.connect(analyser)
        analyser.connect(javascriptNode)
        javascriptNode.connect(audioContext.destination)

        let canvasContext = document.getElementById("canvas").getContext("2d")

        javascriptNode.onaudioprocess = function() {
          var array = new Uint8Array(analyser.frequencyBinCount)
          analyser.getByteFrequencyData(array)
          var values = 0

          var length = array.length
          for (var i = 0; i < length; i++) {
            values += (array[i])
          }

          var average = values / length

          //          console.log(Math.round(average - 40));

          const clippedHeight = (that.height - average) > 0 ? that.height - average : 0

          canvasContext.clearRect(0, 0, width, that.height)
          canvasContext.fillStyle = "#2fd20f"
          canvasContext.fillRect(0, clippedHeight, width, that.height)
        } // end fn stream
      },
      function(err) {
        console.log("The following error occured: " + err.name)
      })
    } else {
      console.log("getUserMedia not supported")
    }
  },
}
</script>

<style scoped>
  #canvas {
    width: 10px;
    height: 100%;
    /*position: absolute;*/
    /*top: 150px;*/
    /*left: 45%;*/
    text-align: center;
    background: #6f6f6f33;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 8px;
  }

  .micVuMeter{
    /*height: 25%;*/
  }
</style>
